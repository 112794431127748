import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stageHighlightLabel: null as string | null | String[],
    stageAnswer: null as string | null,
    isLoading: false,
  },
  mutations: {
    updateStageHighlightLabel(state, ansSet: String[]) {
      state.stageHighlightLabel = ansSet;
    },
    updateStageHighlightLabelMulti(state, ansSet: String[]) {
      state.stageHighlightLabel = ansSet;
    },
    updateStageAnswer(state, label: string) {
      state.stageAnswer = label;
    },
    updateLoading(state, status) {
      state.isLoading = status;
    },
  },
  actions: {
  },
  modules: {
  },
});
