





















import Vue, { PropType } from 'vue';
import { StageEventBus } from '@/libs/EventBus';
import Utils from '@/libs/utils';
import { ImageField } from '@/apis/models/QuizModel';

export default Vue.extend({
  name: 'StageImage',
  props: {
    width: {
      type: Number,
      // required: true,
      default: 200,
    },
    height: {
      type: Number,
      // required: true,
      default: 200,
    },
    field: {
      type: Object as PropType<ImageField>,
      required: false,
      default: null,
    },
    imageSize: {
      type: Number,
      default: 80,
    },
  },
  data() {
    return {
      errorMessage: '',
      isLoading: false,
    };
  },
  computed: {
    imageUrl(): string {
      return this.field.url;
    },
  },
  watch: {
    imageUrl(newUrl: string) {
      if (newUrl !== '') {
        this.isLoading = true;
        this.$store.commit('addStagePendingTask');
        Utils.preloadImage(newUrl).catch(() => {
          this.showErrorMessage('無法讀取檔案');
        }).finally(() => {
          this.$store.commit('finishStagePendingTask');
          this.isLoading = false;
        });
      }
    },
  },
  mounted() {
    StageEventBus.$on('reset-stage', this.reset);
  },
  methods: {
    showErrorMessage(message: string) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = '';
      }, 2000);
    },
    reset() {
      this.errorMessage = '';
      this.isLoading = false;
    },
  },
});
