





























































































































import Vue from 'vue';
import { ToastPlugin } from 'bootstrap-vue';
import liff from '@line/liff';
import Stage from '@/components/stage/Stage.vue'; // @ is an alias to /src
import QuestionList from '@/components/QuestionList.vue';
import { Question } from '@/models/Question';
import VueAudio from '@/components/VueAudio.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
// eslint-disable-next-line import/no-cycle
import QuizApi from '@/apis/QuizApi';
import { CreateRespondModel } from '@/apis/models/CreateModel';
import Status from '@/apis/models/RespondStatus';
import QuizStatus from '@/models/QuizStatus';

Vue.use(ToastPlugin);

export default Vue.extend({
  components: {
    Stage,
    QuestionList,
    VueAudio,
    LanguageSelector,
    ConfirmModal,
  },
  data() {
    return {
      currentNum: 0,
      amount: 0,
      audioUrl: '',
      quizData: [] as unknown[],
      errored: false,
      loading: true,
      modalShow: false,
      counter: 0,
      toastContent: '',
      ansSet: [] as Question[],
      timer: null as any,
      tempSubmitTimer: null as any,
      isAllSelected: false,
      allowLastTip: true,
      allowFirstTip: true,
      allowAllSelectedTip: true,
      Status,
      QuizStatus,
      clientType: '',
    };
  },
  watch: {
    modalShow: {
      handler(newData, oldData) {
        if (newData) {
          this.isCountTime(false);
        } else if (oldData && !newData) {
          this.isCountTime(true);
        }
      },
    },
    currentNum: {
      handler() {
        this.isCountTime(true);
      },
    },
  },
  beforeCreate() {
    this.$i18n.locale = this.$route.query.language as string;
  },
  async created() {
    this.clientType = this.$route.query.clientType as string;
    let quizCodeStatus = QuizStatus.INITIAL;// -1
    this.$store.commit('updateLoading', true);
    try {
      const status = await QuizApi.check(this.$route.query.code as string);
      if (status === Status.TEMP_SUBMITTED || status === Status.STARTED || status === Status.TRY_AGAIN) {
        quizCodeStatus = QuizStatus.VALID; // The quiz code is valid.
        const instance = await QuizApi.create(this.$route.query.code as string) as CreateRespondModel;
        console.log(`instance: ${JSON.stringify(instance)}`);
        this.quizData = instance.answerList;
        this.amount = instance.amount;
        const newAnswerList = instance.answerList as Array<any>;
        for (let i = 0; i < instance.amount; i += 1) {
          const {
            userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect,
          } = newAnswerList[i];
          newAnswerList[i] = new Question(userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect);
        }
        this.ansSet = newAnswerList;
        this.isCountTime(true);
        this.tempSubmitTimer = window.setInterval(this.tempSubmit, 10000);
        this.checkIsAllSelected();
      } else if (status === Status.SUBMMITED) {
        quizCodeStatus = QuizStatus.SUBMMITED; // The quiz code already used - 1
      }
    } catch (error: unknown) {
      const err = error as any;
      this.errored = true;
      if (err.response.status === 404) {
        quizCodeStatus = QuizStatus.NOT_EXISTING; // The quiz code is invalid 2
      }
    } finally {
      if (quizCodeStatus !== QuizStatus.VALID) { // The quiz code is valid.
        this.$router.push({
          path: '/tip',
          query: {
            quizCodeStatus: `${quizCodeStatus}`,
          },
        });
      }
      const requestNum = this.$route.query.num as unknown as number;
      if (requestNum <= this.amount) {
        this.currentNum = (requestNum - 1);
      }
      this.$store.commit('updateLoading', false);
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    previousBtn() {
      if (this.currentNum - 1 > -1) {
        this.currentNum -= 1;
        this.androidUpdateNum();
      } else if (this.allowFirstTip) {
        this.toastContent = this.$i18n.t('已經是第一題') as string;
        this.toast('b-toaster-top-center');
      }
    },
    nextBtn() {
      if (this.currentNum + 1 < this.amount) {
        this.currentNum += 1;
        this.androidUpdateNum();
      } else if (this.allowLastTip) {
        this.toastContent = this.$i18n.t('已經是最後一題') as string;
        this.toast('b-toaster-top-center');
      }
    },
    listBtn() {
      this.modalShow = true;
    },
    toast(toaster: string, append = false) {
      // this.counter += 1;
      this.allowLastTip = false; this.allowFirstTip = false;
      this.$bvToast.toast(`${this.toastContent}`, {
        title: this.$i18n.t('抱歉') as string,
        toaster,
        solid: true,
        appendToast: append,
        autoHideDelay: 900,
      });
      setTimeout(() => { this.allowLastTip = true; this.allowFirstTip = true; }, 1200);
    },
    updateCurrentNum(e:number) {
      this.currentNum = e;
      this.modalShow = false;
      this.androidUpdateNum();
    },
    updateSubOfAnsSet(data:[]) {
      this.ansSet[this.currentNum].userAnswer = data;
    },
    async submitBtn() {
      const data = { ansSet: this.ansSet.map((obj) => JSON.parse(`{"uuid":"${obj.uuid}", "timeSpent":${obj.timeSpent}, "userAnswer":${JSON.stringify(obj.userAnswer)}}`)) };
      // const data = { ansSet: setArray } as unknown;

      this.isCountTime(false);
      window.clearInterval(this.tempSubmitTimer);
      this.$store.commit('updateLoading', true);

      try {
        await QuizApi.submit(this.$route.query.code as string, data as unknown);
        this.$store.commit('updateLoading', false);
        if (liff.isInClient()) {
          liff.sendMessages([{
            type: 'text',
            text: `complete.${this.$route.query.code}`,
          }]);
        } else {
          QuizApi.browserSubmit(this.$route.query.code as string, '{}');
        }
        this.$router.push({ path: `/submitted?clientType=${this.clientType}` });
      } catch (error) {
        this.isCountTime(true);
        this.tempSubmitTimer = window.setInterval(this.tempSubmit, 10000);
      }
    },
    tempSubmit() {
      const data = { ansSet: this.ansSet.map((obj) => JSON.parse(`{"uuid":"${obj.uuid}", "timeSpent":${obj.timeSpent}, "userAnswer":${JSON.stringify(obj.userAnswer)}}`)) };

      try {
        QuizApi.save(this.$route.query.code as string, data as unknown);
      } catch (error) {
        console.log(error);
      }
    },
    isCountTime(data: boolean) {
      if (data) {
        window.clearInterval(this.timer);
        this.timer = null;
        this.timer = window.setInterval(this.addTime, 100);
      } else {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },
    addTime() {
      this.ansSet[this.currentNum].timeSpent += 100;
    },
    doubleClick() { // For handling double click event
      console.log('double');
    },
    checkIsAllSelected() {
      let unfinishedCount = 0;
      for (let i = 0; i < this.ansSet.length; i += 1) {
        if (!this.checkSignleQuestion(this.ansSet[i].userAnswer)) {
          unfinishedCount += 1;
        }
      }
      if (unfinishedCount === 0) {
        this.isAllSelected = true;
        if (this.allowAllSelectedTip) {
          this.allowAllSelectedTip = false;
          this.$bvToast.toast(`${this.$i18n.t('已答完所有題目')}`, {
            toaster: 'b-toaster-bottom-center',
            title: this.$i18n.t('完成') as string,
            solid: true,
            appendToast: false,
            autoHideDelay: 1000,
          });
          setTimeout(() => { this.allowAllSelectedTip = true; }, 1000);
        }
      } else {
        this.isAllSelected = false;
      }
    },
    checkSignleQuestion(data: any) {
      const checkResult = false;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i] !== '' && data[i] !== undefined && data[i] !== null) {
          return true;
        }
      }
      return checkResult;
    },
    androidUpdateNum() {
      const u = navigator.userAgent;
      if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        window.AndroidWebView.updateQuesNum(this.currentNum + 1);
      }
    },
  },
});
