




















import Vue from 'vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import QuizStatus from '@/models/QuizStatus';

export default Vue.extend({
  components: {
    LanguageSelector,
  },
  data() {
    return {
      quizCodeStatus: -1,
      QuizStatus,
    };
  },
  created() {
    this.quizCodeStatus = this.$route.query.quizCodeStatus as unknown as number;
  },
});
